<template>
  <div id="app">
    <Menu currentmenu="home" />
    
    <!--Show dynamic banner (if required)-->
    <div v-show="showHeaderBanner" class="banner-1" v-html="bannerMessage"></div>

    <div class="hero-1">
      <!-- <img src="../../assets/hero.jpg" /> -->
    </div>

    <div class="separator-1">&nbsp;</div>

    <div class="main-body-wrap">
      <div class="section-1">
        <div class="grid-container">
          <div class="grid-x">
            <div class="cell small-12 medium-12 large-6 bg-1-wrap">
              <div class="brushed-bg-1">
                <div class="section-text">
                  <h1>What we do</h1>

                  <p>Jesus makes all the difference!</p>
                  <p>
                    We're a new family church in Selwyn. 
                    We meet weekly in local homes 11am on Sundays (to find out where, email <a href="mailto:kiaora@springscommunitychurch.nz">kiaora@springscommunitychurch.nz</a>). 
                    <br />We have fun looking into the Bible each week with games, memory verses, songs and prayers. 
                    <br />Each week we celebrate communion (remembering the death and resurrection of Jesus) and finish with lunch together.
                  </p>
                  <p>
                    Mid-week some of the adults get together to look into the
                    Bible and pray, aiming to live out Christian faith in
                    everyday life because Jesus makes all the difference.
                  </p>
                </div>
              </div>
            </div>
            <div class="cell small-12 medium-12 large-6 bg-2-wrap">
              <div class="brushed-bg-2">
                <div class="section-text">
                  <h1>Who we are</h1>
                  <p>
                    Springs is the area of Selwyn where we're based, with people
                    involved who live in Lincoln, Halswell, Prebbleton and
                    Springston.
                  </p>
                  <p>
                    Community is our aim, where anyone is welcome to find out
                    more about Jesus.
                  </p>
                  <p>
                    Church is what we are, following Jesus in the whole of life.
                  </p>
                  <p>
                    We're a small relatable bunch of people and would love you
                    to visit!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="separator-2">
        <div class="section-text">
          <h1>You are welcome!</h1>
          <span>If you're not usually part of a church, or have questions about
            Jesus, we'd love to meet you.</span>
          <p></p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  components: {
    Menu: () => import(/* webpackChunkName: 'menu' */ "@/components/Menu.vue"),
    Footer: () =>
      import(/* webpackChunkName: 'menu' */ "@/components/Footer.vue"),
  },
  name: "Home",
  data() {
    return {
      showHeaderBanner: this.getBannerMessage() != '',
      bannerMessage: this.getBannerMessage()
    };
  },
  methods: {
    isDateBetween(currentDate, startDate, endDate) {
      return currentDate > startDate && currentDate < endDate;
    },
    getBannerMessage() {
      const currentDate = new Date();
      
      if (this.isDateBetween(currentDate, new Date('2023-12-01'), new Date('2024-01-22'))){
        return 'We\'ll be meeting in local homes instead of Ararira Springs School on Sundays between December 17th and January 21st inclusive. To find out each different Sunday location email us <a href="mailto:kiaora@springscommunitychurch.nz">kiaora@springscommunitychurch.nz</a>';
      }
      return '';
    }
  },
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "@/scss/_main.scss";

.hero-1 {
  padding-top: 140px;
  min-height: 40vh;
  background-image: url("../../assets/hero.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Default styles */
.section-1 {
  .grid-container {
    padding-left: 0;
    padding-right: 0;
  }

  .bg-1-wrap {
    background-color: $color-4;
  }

  .bg-2-wrap {
    background-color: $color-5;
  }
}

.separator-1,
.separator-2 {
  //background-color: $color-5;
  background: rgb(243, 122, 52);
  background: linear-gradient(7deg,
      rgba(243, 122, 52, 1) 27%,
      rgba(237, 50, 44, 1) 100%);
  padding: 1rem;
}

.separator-2 {
  color: #fff;
  padding: 2rem 4rem 2rem 3rem;
}

.brushed-bg-1 {
  padding: 2rem 4rem 2rem 3rem;
  color: #fff;

  .section-text {
    background-color: $color-4;
  }
}


.brushed-bg-2 {
  padding: 2rem 4rem 2rem 3rem;
  color: #fff;
}

a.map-link {
  color: #fff;
  text-decoration: underline;
}

.banner-1 {
  background-color: $color-5;
  color: #fff;
  padding: 1rem;
  a{
    color: #fff;
    text-decoration: underline;
  }
}

/* Small only */
@media screen and (max-width: 39.9375em) {}

/* Medium and up */
@media screen and (min-width: 40em) {
  .hero-1 {
    min-height: 50vh;
    background-attachment: fixed;
  }

  .section-1 {
    .grid-container {
      padding: 0;
    }

    .bg-1-wrap {
      background-color: unset;
    }

    .bg-2-wrap {
      background-color: unset;
    }

    .brushed-bg-1 {
      background: url("../../assets/brushed-background-1.png");
      background-repeat: no-repeat;
      background-size: 100% 115%;
      padding: 2rem 4rem 2rem 3rem;
      color: #fff;

      .section-text {
        background-color: $color-4;
      }
    }

    .brushed-bg-2 {
      background: url("../../assets/brushed-background-2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 2rem 4rem 2rem 3rem;
      color: #fff;
    }
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {}

/* Large and up */
@media screen and (min-width: 64em) {
  .hero-1 {
    min-height: 80vh;
  }

  .section-1 {
    //background-color: $color-1;
    padding: 2.6rem 2.6rem 2.6rem 2.6rem;
  }

  .separator-2 {
    margin-top: -4.6rem;
    padding-top: 2rem;

    .section-text {
      h1 {
        padding: 2rem;
        //padding-bottom: 1rem;
      }
    }
  }
}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {}

// .section-2 {
//   padding: 2.6rem 2.6rem 0 2.6rem;
//   background-color: $color-3;
//   color: #fff;
// }
</style>
